<script>
	import Icon from 'svelte-awesome';
  	import { faGithub, faDiscord } from '@fortawesome/free-brands-svg-icons';
</script>

<main>
	<div class="background-main flex h-screen min-w-screen">
		<div class="flex flex-col my-auto mx-auto space-y-4">
			<div class="sm:flex sm:h-[33vh] mx-auto space-x-6">
				<img srcset="./ref/pfp/pfp@100px.png 100w,
				./ref/pfp/pfp@200px.png 200w,
				./ref/pfp/pfp@250px.png 250w,
				./ref/pfp/pfp@500px.png 500w,
				./ref/pfp/pfp@1000px.png 1000w,
				./ref/pfp/pfp@1250px.png 1250w,
				./ref/pfp/pfp@1500px.png 1500w,
				./ref/pfp/pfp@1750px.png 1750w,
				./ref/pfp/pfp@2000px.png 2000w,
				./ref/pfp/pfp@2250px.png 2250w,
				./ref/pfp/pfp@2360px.png 2360w,
				./ref/pfp/pfp@2500px.png 2500w"
				alt="Profile" class="max-w-[60vw] sm:w-auto sm:h-full bg-blue-200 rounded-full shadow-xl">
				<div class="flex flex-col my-auto space-y-2 font-ubuntu-mono font-normal text-purple-200 xl:text-4xl lg:text-3xl text-2xl">
					<!--This is a hacky workaround to center the middle and still have text below.
						This is probably fixable with absolute positioning and flexbox, but idk how
						Someone please enlighten me.
					-->
					<div class="invisible">
						<hr class="border-[0.1rem] w-1/12">
						<div class="pt-2">	
								<Icon data={faDiscord} scale={1.5}/>
						</div>
					</div>
					<div>
						<p>Hi, I'm Henry.</p>
						<p>aka. ProbablyanAsian</p>
					</div>
					<div>
						<hr class="bg-white border-[0.1rem] w-5/12">
						<div class="inline-block text-xl pt-2 space-x-2">	
							<a class="icon-container" href="https://github.com/probablyanasian">
								<Icon class="text-white" data={faGithub} scale={1.5}/>
								<p class="icon-ref github-icon">@probablyanasian</p>
							</a>
							<div tabindex=0 class="icon-container">
								<Icon class="text-white" data={faDiscord} scale={1.5}/>
								<p class="icon-ref discord-icon">probablyanasian</p>
							</div>
							<a class="icon-container" href="https://matrix.to/#/@probablyanasian:catboy.engineer?via=catboy.engineer&via=matrix.org">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" class="pt-[0.085rem] text-white inline-block" viewBox="0 0 28 32" stroke="currentColor">
									<g fill="#ffffff">
										<path d="m27.1 31.2v-30.5h-2.19v-0.732h3.04v32h-3.04v-0.732z"/>
										<path d="m8.23 10.4v1.54h0.044c0.385-0.564 0.893-1.03 1.49-1.37 0.58-0.323 1.25-0.485 1.99-0.485 0.72 0 1.38 0.14 1.97 0.42 0.595 0.279 1.05 0.771 1.36 1.48 0.338-0.5 0.796-0.941 1.38-1.32 0.58-0.383 1.27-0.574 2.06-0.574 0.602 0 1.16 0.074 1.67 0.22 0.514 0.148 0.954 0.383 1.32 0.707 0.366 0.323 0.653 0.746 0.859 1.27 0.205 0.522 0.308 1.15 0.308 1.89v7.63h-3.13v-6.46c0-0.383-0.015-0.743-0.044-1.08-0.0209-0.307-0.103-0.607-0.242-0.882-0.133-0.251-0.336-0.458-0.584-0.596-0.257-0.146-0.606-0.22-1.05-0.22-0.44 0-0.796 0.085-1.07 0.253-0.272 0.17-0.485 0.39-0.639 0.662-0.159 0.287-0.264 0.602-0.308 0.927-0.052 0.347-0.078 0.697-0.078 1.05v6.35h-3.13v-6.4c0-0.338-7e-3 -0.673-0.021-1-0.0114-0.314-0.0749-0.623-0.188-0.916-0.108-0.277-0.3-0.512-0.55-0.673-0.258-0.168-0.636-0.253-1.14-0.253-0.198 0.0083-0.394 0.042-0.584 0.1-0.258 0.0745-0.498 0.202-0.705 0.374-0.228 0.184-0.422 0.449-0.584 0.794-0.161 0.346-0.242 0.798-0.242 1.36v6.62h-3.13v-11.4z"/>
										<path d="m0.936 0.732v30.5h2.19v0.732h-3.04v-32h3.03v0.732z"/>
									</g>
								</svg>
								<!-- Hack to allow overflow without resizing div -->
								<p class="absolute icon-ref matrix-catboy-engineer-icon">&nbsp;@probablyanasian:catboy.engineer</p>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="h-[15vh] z-0"></div>
			<div class="absolute self-center bottom-12 w-[4.1666666%] space-y-2">
				<svg xmlns="http://www.w3.org/2000/svg" class="mx-auto text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
				</svg>
				<hr class="bg-white border-[0.1rem]">
			</div>
		</div>
	</div>
	<!-- FIRST PAGE SEPERATION -->
	<div class="bg-gray-700 flex h-screen min-w-screen">
		<div class="flex flex-col my-auto mx-auto space-y-4">
			<div class="sm:flex sm:h-[33vh] mx-auto space-x-6">
				<img srcset="./ref/pfp/pfp@100px.png 100w,
				./ref/pfp/pfp@200px.png 200w,
				./ref/pfp/pfp@250px.png 250w,
				./ref/pfp/pfp@500px.png 500w,
				./ref/pfp/pfp@1000px.png 1000w,
				./ref/pfp/pfp@1250px.png 1250w,
				./ref/pfp/pfp@1500px.png 1500w,
				./ref/pfp/pfp@1750px.png 1750w,
				./ref/pfp/pfp@2000px.png 2000w,
				./ref/pfp/pfp@2250px.png 2250w,
				./ref/pfp/pfp@2360px.png 2360w,
				./ref/pfp/pfp@2500px.png 2500w"
				alt="Profile" class="max-w-[60vw] sm:w-auto sm:h-full bg-blue-200 rounded-full shadow-xl">
				<div class="flex flex-col my-auto space-y-2 font-ubuntu-mono font-normal text-purple-200 xl:text-4xl lg:text-3xl text-2xl">
					<div>
						<p>Work in progress...</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>

<style>
	div.background-main {
		background:
			radial-gradient(farthest-side at top left,
				rgba(204, 161, 247, 0.773) 5%, transparent 95%),
			radial-gradient(farthest-side at bottom right,
				rgba(204, 161, 247, 0.773) 5%, transparent 95%);
		background-color:theme("colors.cust_purple.600");
	}
	
	a:visited {
		@apply text-purple-200;
	}

	.icon-container {
		@apply inline-block no-underline align-middle;
	}

	a.icon-container:hover .github-icon, a.icon-container:focus .github-icon {
		width: 17ch;
		@apply duration-500;
	}
	
	div.icon-container:hover .discord-icon, div.icon-container:focus .discord-icon {
		width: 22ch;
		@apply duration-700;
	}

	a.icon-container:hover .matrix-catboy-engineer-icon, a.icon-container:focus .matrix-catboy-engineer-icon {
		width: 33ch;
		transition-duration: 850ms;
	}

	.icon-ref {
		transition: all ease-in-out;
		transition-duration:850ms;
		@apply align-bottom inline-block overflow-hidden w-0 flex-nowrap;
	}
</style>